import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import SEO from 'components/SEO';
import Layout from 'components/Layout';
import Heading from 'components/Heading';

const Wrapper = styled.section`
  text-align: center;
  figure {
    margin: 0 auto;
    max-width: 450px;
  }
  span.signiture {
    display: block;
    font-style: italic;
    font-size: 100%;
  }
`;

const ThankYouPage = () => (
  <StaticQuery
    query={graphql`
      query THANK_YOU_QUERY {
        thankYouDog: file(relativePath: { eq: "global/dog-thankyou.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <SEO title="Message Sent!" />
        <Wrapper>
          <Heading text="Message Sent!" />
          <p>
            Thank you for your message! I will get back to you as soon as
            possible.
            <span className="signiture">&mdash; Cheri</span>
          </p>
          <figure>
            <Img
              fluid={data.thankYouDog.childImageSharp.fluid}
              alt="Dog with thankyou sign"
            />
          </figure>
        </Wrapper>
      </Layout>
    )}
  />
);

export default ThankYouPage;
